module.exports = [{
      plugin: require('/home/travis/build/zachlysobey/personal-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-29116435-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"auto"},
    },{
      plugin: require('/home/travis/build/zachlysobey/personal-site/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/zachlysobey/personal-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
